@import 'antd/dist/antd.css';

body {
  margin: 0px;
  padding: 0px;
  width: 100%;
  overflow-x: hidden;
  background: black;
}

.anticon {
  vertical-align: text-top;
}

.home {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  padding: 43px;
}

.home form {
  min-width: 50%;
  background-color: #252729 !important;
  border-radius: 10px;
  padding: 43px;
}

.home  form h4 {
  margin-bottom: 23px;
}

.home form .form-floating {
  margin-bottom: 23px;
}

.home form .label-styles {
  color: white;
}

.Results {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  padding: 43px;
}

.Results > div {
  min-width: 70%;
  background-color: #252729 !important;
  border-radius: 10px;
  padding: 43px;
  color: white;
}

.Results h2, h3, h4, h5 {
  color: white;
}

.table thead {
  color: white !important;
}

.table tr td {
  color: white !important;
}

/*
table
{
    counter-reset: rowNumber;
}

table tr > td:first-child
{
    counter-increment: rowNumber;
}

table tr td:first-child::before
{
    content: counter(rowNumber);
    min-width: 1em;
    margin-right: 0.5em;
}
*/

@media (max-width: 500px) {
   .Results > div, .home form {
     width: 100% !important;
   }
}